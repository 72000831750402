import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'CardList',
    props: {
        data: {
            default: () => [],
            type: Array,
        },
        options: {
            default: () => {
                return {
                    slug: '',
                    title: '',
                    to: '',
                };
            },
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const articles = computed(() => props.data?.map(({ data }) => data)) || [];
        return {
            articles,
            css,
        };
    },
});
