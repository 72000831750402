import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { SIZE_MAP } from './';
import { getArticleInfo } from '~/utils';
export default defineComponent({
    name: 'ArticlesList',
    props: {
        articles: {
            required: true,
            type: Array,
        },
        size: {
            default: 'short',
            type: String,
            validator: (value) => {
                return ['short', 'tall'].includes(value);
            },
        },
        shouldTruncateText: {
            default: true,
            type: Boolean,
        },
        title: {
            default: '',
            type: String,
        },
        to: {
            default: '',
            type: String,
        },
        theme: {
            default: 'light',
            type: String,
            validator: (value) => {
                return ['dark', 'light'].includes(value);
            },
        },
    },
    setup(props, { root }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const primaryItem = computed(() => {
            const firstArticle = (props.articles || [])[0] || {};
            const { photo, photo_present: photoPresent, title, video = null } = firstArticle;
            return {
                photo,
                photo_present: photoPresent,
                title,
                video,
                ...getArticleInfo.call(root, firstArticle),
            };
        });
        const items = computed(() => {
            return props.articles?.slice(1, SIZE_MAP[props.size]).map((article) => {
                const { title } = article;
                return {
                    title,
                    ...getArticleInfo.call(root, article),
                };
            });
        });
        return {
            css,
            items,
            primaryItem,
        };
    },
});
