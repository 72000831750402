// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ktSn0{position:relative;display:flex}.S6a6F{position:absolute;top:0;left:0;display:flex;width:100%;height:100%;align-items:center;justify-content:center;background-color:#fff;border-radius:8px;box-shadow:inset 0 0 0 1px #e2e2e2}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardList": "ktSn0",
	"loader": "S6a6F"
};
module.exports = ___CSS_LOADER_EXPORT___;
